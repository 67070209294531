import React from "react";
import "../assets/css/App.css";
import "../assets/css/index.css";
import { Button, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Img from "gatsby-image";
import SEO from "../components/SEO";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

const StyledButton = styled(Button)`
  & {
    margin: 0;
  }
`;

export default ({ data }) => (
  <Layout>
    <SEO
      title="Plant Room Installation at Wimpole Street"
      description="BBIS Heating specialises in commercial plant room installations on Wimpole Street, delivering tailored solutions for businesses in the heart of London. Trust us for precision and efficiency in creating optimal heating infrastructures."
      keywords="plant room installation, plant room renovation, plant room commercial heating installs, plant room contractor, heating plant room london"
    />
<div class="grey-segment text-left">
<h1 class="heading-color">Plant Room Installation</h1>
      <h4 class="sub-heading pb-2">Wimpole Street, London</h4>
      <Row>
        <Col lg>
          <Img
            fluid={data.wimpole.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
          <h4 class="sub-heading pt-4">Project details</h4>
          <p>
            The old boiler room occupied two rooms and was deigned and built in
            the 1980’s. The plant was removed and a complete new boiler made in
            half the original space. This allowed the other half to be turned in
            to a reception area and washroom facilities.
          </p>
          <p>
            The boiler room was started afresh from a bare room. Careful
            consideration and planning was involved to make sure all plant and
            pipework went in to the available space. Three 115 KW Remeha boilers
            provide the heating, and the hot water is stored in 2 large
            calorifiers.
          </p>
          <p>
            All pipework and flues were fabricated on site and a bespoke control
            panel, was installed to run the boilers, pumps and valves via a
            TREND BMS system.
          </p>
          <StyledButton
            href="https://hdvirtualart.com/tours/bbis/001/"
            variant="primary"
            size="lg"
            target="_blank"
          >
            View 360 tour
          </StyledButton>
        </Col>
      </Row>
    </div>
  </Layout>
);

export const query = graphql`
  query WimpoleImages {
    wimpole: file(relativePath: { eq: "Projects/wimpole.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 1137) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
